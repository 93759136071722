<script setup lang="ts">
</script>

<template>
  <div class="header">
    <img src="/images/logo.png"  alt="logo"/>
  </div>
</template>

<style scoped>
.header {
  background-color: #000;
  display: flex;
  justify-content: center;
}
</style>