<script setup lang="ts">

</script>

<template>
  <div class="footer">
    <div>Règlement du jeu</div>
    <div>Mentions légales</div>
    <div>Contactez-nous</div>
  </div>
</template>

<style scoped>
.footer {
  display: flex;
  column-gap: 2rem;
  justify-content: center;
  color: #FFF;
  font-family: "Zeroes Two", sans-serif;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%; /* 22px */
  text-decoration: underline;
  text-decoration-thickness: 1px;
  text-transform: uppercase;
  margin: 60px auto;
  flex-wrap: wrap;
  row-gap: 1rem;
}
</style>