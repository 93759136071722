import { default as index34fOUR6kynMeta } from "C:/Users/HP/WebstormProjects/polaire/pages/index.vue?macro=true";
export default [
  {
    name: index34fOUR6kynMeta?.name ?? "index",
    path: index34fOUR6kynMeta?.path ?? "/",
    meta: index34fOUR6kynMeta || {},
    alias: index34fOUR6kynMeta?.alias || [],
    redirect: index34fOUR6kynMeta?.redirect,
    component: () => import("C:/Users/HP/WebstormProjects/polaire/pages/index.vue").then(m => m.default || m)
  }
]